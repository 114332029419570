import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/Signup.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ChatBots from '../views/ChatBots.vue'
import Billing from '../views/Billing.vue'
import CreateChatBot from '../views/CreateChatBot.vue'
import EditChatBot from '../views/EditChatBot.vue'
import EditTopic from '../views/EditTopic.vue'
import ChatWindow from '../views/ChatWindow.vue'
import BillingResult from '../views/BillingResult.vue'
import Pricing from '../views/Pricing.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsConditions from '../views/TermsConditions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  },
  {
    path: '/chatbots',
    name: 'ChatBots',
    component: ChatBots
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },
  {
    path: '/billing/result',
    name: 'BillingResult',
    component: BillingResult
  },
  {
    path: '/chatbot/create/:id',
    name: 'CreateChatBot',
    component: CreateChatBot
  },
  {
    path: '/chatbot/chat/:id',
    name: 'ChatWindow',
    component: ChatWindow
  },
  {
    path: '/chatbot/manage/:id',
    name: 'EditChatBot',
    component: EditChatBot
  },
  {
    path: '/chatbot/manage/:id/topics/:topicId',
    name: 'EditTopic',
    component: EditTopic
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/create-account',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/',
    name: 'ForgotPassword',
    component: ForgotPassword
  }
]

const router = new VueRouter({
  routes
})

export default router
