<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div class="flex-grow h-full pt-10">
        <div v-if="showLink"
          class="z-50 fixed flex items-center justify-center top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50"
        >
          <div class="w-96 p-10 rounded-xl bg-white shadow-lg relative">
            <div @click="showLink = false" class="absolute top-0 right-0 m-4 cursor-pointer hover:text-blue-600">
              <i class="fa-solid fa-xmark"></i>
            </div>
            <label for="link" class="block text-gray-700 font-normal text-sm mb-1"
                  >Link to your resource</label
                >
            <div class="w-full flex space-x-1">
              
              <div class="flex-shrink">
                
                <input
                  v-model="link"
                  type="link"
                  id="link"
                  placeholder="https://"
                  class="w-full rounded-lg px-4 h-12 border border-gray-200 bg-gray-200 focus:outline-none focus:bg-white"
                  required
                />
              </div>
              <div class="flex-shrink">
                <div @click="addResource()" class=" flex items-center h-12 px-4 justify-center bg-blue-600 text-white rounded cursor-pointer hover:bg-blue-700">
                  Save
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-7xl mx-auto h-full flex">
          <div class="h-full w-2/3 flex flex-col px-10">
            <div v-if="topic" class="w-full flex items-center h-32">
              <div class="flex-shrink mt-10">
                <router-link
                  :to="{ name: 'ChatBots' }"
                  class="text-blue-600 cursor-pointer hover:underline text-sm"
                  >Chatbots</router-link
                >
                <div>
                  <input
                    type="text"
                    v-model="topic.title"
                    class="outline-none w-full h-20 text-3xl font-bold text-gray-800"
                    placeholder="Give your chatbot a title"
                  />
                </div>
              </div>
            </div>

            <div
              ref="editorElement"
              class="editable w-full pb-20 h-full overflow-y-scroll bg-white outline-none"
            ></div>
          </div>

          <div v-if="topic" class="h-full w-1/3 flex flex-col border-l border-gray-200">
            <div class="h-full p-10">
              <h2 class="font-bold text-xl">Add Resources</h2>

              <div class="w-full grid grid-cols-4 gap-2 mt-4">
                <div class="col-span-1">
                  <div
                    @click="[showLink = true, linkType='video']"
                    class="hover:bg-gray-100 cursor-pointer h-20 w-20 rounded-xl border border-gray-100 shadow-lg p-4 flex items-center justify-center"
                  >
                    <div class="text-center">
                      <i class="fa-solid fa-video text-red-700"></i>
                      <div class="text-xs mt-0 text-gray-400">Video</div>
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    @click="[showLink = true, linkType='image']"
                    class="hover:bg-gray-100 cursor-pointer h-20 w-20 rounded-xl border border-gray-100 shadow-lg p-4 flex items-center justify-center"
                  >
                    <div class="text-center">
                      <i class="fa-solid fa-image text-green-600"></i>
                      <div class="text-xs mt-0 text-gray-400">Image</div>
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    @click="[showLink = true, linkType='file']"
                    class="hover:bg-gray-100 cursor-pointer h-20 w-20 rounded-xl border border-gray-100 shadow-lg p-4 flex items-center justify-center"
                  >
                    <div class="text-center">
                      <i class="fa-solid fa-paperclip text-blue-600"></i>
                      <div class="text-xs mt-0 text-gray-400">File</div>
                    </div>
                  </div>
                </div>

                <div class="col-span-1">
                  <div
                    @click="[showLink = true, linkType='link']"
                    class="hover:bg-gray-100 cursor-pointer h-20 w-20 rounded-xl border border-gray-100 shadow-lg p-4 flex items-center justify-center"
                  >
                    <div class="text-center">
                      <i class="fa-solid fa-link text-purple-600"></i>
                      <div class="text-xs mt-0 text-gray-400">Link</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full" v-if="topic.resources.length > 0">
              <h2 class="font-bold text-base mt-10" >Links</h2>
              <div class="w-full flex flex-wrap space-x-3" v-for="(r, index) in topic.resources" :key="index">
                <div class="flex-shrink">
                  <div class="w-40">
                    <a class=" text-blue-500 cursor-pointer" :href="r.link"><h2 class="truncate">{{ r.link }}</h2></a>
                  </div>
                </div>
                <div class="flex-grow">
                  <span class="bg-gray-100 rounded px-2 py-1 text-gray-400 text-xs">
                    {{ r.type }}
                  </span>
                </div>
                <div class="flex-shrink">
                  <i @click="removeResource(index)" class="fa-solid fa-xmark text-red-600 cursor-pointer"></i>
                </div>
              </div>
            </div>
            </div>

            <div
              class="h-40 px-10 flex items-center justify-end border-t border-gray-200"
            >
              <router-link
                v-if="this.bot"
                :to="{ name: 'EditChatBot', params: { id: this.bot.id } }"
                class="w-40 h-16 mr-2 hover:bg-blue-700 hover:text-white cursor-pointer rounded-lg border-2 border-blue-600 text-blue-600 flex items-center justify-center"
              >
                Go Back
              </router-link>

              <div
                @click="saveTopic()"
                class="w-40 h-16 hover:bg-blue-700 cursor-pointer rounded-lg bg-blue-600 text-white flex items-center justify-center"
              >
                Save Topic
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { getItem, setItem } from "@/firebaseUtils";
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";

import MediumEditor from "medium-editor";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";

export default {
  data() {
    return {
      bot: null,
      topic: null,
      items: [],
      showLink: false,
      linkType: '',
      link: '',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    removeResource(index){
      this.topic.resources.splice(index, 1)
    },
    addResource(){
      this.topic.resources.push({
        type: this.linkType,
        link: this.link
      })
      this.link = ''
      this.linkType = ''
      this.showLink = false
    },
    async initiate() {
      this.bot = await getItem({
        collection: "bots",
        doc: this.$route.params.id,
      });
      this.topic = await getItem({
        collection: "topics",
        doc: this.$route.params.topicId,
      });

      if(this.topic && !this.topic.resources) this.$set(this.topic, 'resources', [])

      this.editor.setContent(this.topic.body);
    },
    async saveTopic() {
      this.topic.body = this.editor.getContent(); // Update topic.body with the editor content
      await setItem({
        collection: "topics",
        doc: this.topic.id,
        body: this.topic,
      });
      this.$store.commit("updateState", {
        state: "currentAlert",
        body: {
          message: "Successfully saved your topic",
          icon: '<i class="fa-solid fa-check"></i>',
        },
      });
    },
  },
  created() {
    this.initiate();
  },
  mounted() {
    const editorOptions = {
      placeholder: {
        /* This example includes the default options for placeholder,
           if nothing is passed this is what it used */
        text: "Start tping your topic, your chatbot will use think knowledge to respond on your behalf.",
        hideOnClick: true,
      },
      // Here, you can customize the editor's options
      // Check the documentation for available options: https://github.com/yabwe/medium-editor
      onSave: () => this.saveTopic(), // Call the saveTopic() method when the editor content is saved
    };

    this.editor = new MediumEditor(this.$refs.editorElement, editorOptions);
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  components: {
    SideNav,
    PrimaryNav,
  },
};
</script>
