<template>
  <div
    class="mb-2 w-full flex rounded-lg border border-gray-200 rounded p-6 mb-2"
  >
    <div class="flex-shrink">
      <h2
        class="text-lg text-gray-700 hover:text-blue-600 cursor-pointer font-bold"
      >
        {{topic.title}}
      </h2>
      <h3 class="text-xs text-gray-400">Created: 1 July 2023</h3>
    </div>

    <div class="flex-shrink ml-auto">
      <router-link :to="{name: 'EditTopic', params: {id: topic.botId, topicId: topic.id}}"
        class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-transparent border-blue-600 border text-blue-600 hover:text-white flex items-center justify-center"
      >
        <i class="fa-solid fa-ellipsis-vertical mr-2 text-xs"></i> Edit
    </router-link>
    </div>
    

    
  </div>
</template>

<script>
export default {
    props:['topic']
}
</script>