<template>
  <div class="w-full flex flex-col h-screen overflow-y-hidden">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div v-if="bot" class="flex-grow h-full pt-10">
        <div class="max-w-7xl mx-auto h-full flex flex-col">
          <div class="h-full p-10 overflow-y-scroll">
            <router-link
              :to="{ name: 'ChatBots' }"
              class="text-blue-600 cursor-pointer hover:underline text-sm"
              >Chatbots</router-link
            >
            <h1 class="text-3xl font-bold text-gray-800">Create a chatbot</h1>
            <p class="text-gray-500 mt-3 text-sm">
              Let's start by adding a few details about your chatbot. <br />In
              the next section we'll start teaching it.
            </p>

            <div class="w-full sm:w-1/2">
              <form @submit.prevent="handleSubmit" class="">
                <div v-if="!this.avatarLoading" class="mb-4 w-full">
                  <label for="avatar" class="block text-gray-700 font-bold mb-2"
                    >Avatar</label
                  >
                  <input
                    type="file"
                    accept="image/*"
                    id="avatar"
                    @change="handleAvatarUpload"
                    class="focus:border-blue-600 border-2 w-full rounded-lg px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white"
                  />
                </div>
                <div class="mb-4 w-full">
                  <label for="title" class="block text-gray-700 font-bold mb-2"
                    >Title</label
                  >
                  <input
                    v-model="bot.title"
                    type="text"
                    id="title"
                    class="focus:border-blue-600 border-2 w-full rounded-lg px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white"
                    required
                  />
                </div>
                <div class="mb-4 w-full">
                  <label
                    for="description"
                    class="block text-gray-700 font-bold mb-2"
                    >Description</label
                  >
                  <textarea
                    v-model="bot.description"
                    id="description"
                    class="focus:border-blue-600 border-2 w-full rounded-lg px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white"
                    rows="4"
                    maxlength="200"
                    required
                  ></textarea>
                  <p v-if="bot.description" class="text-gray-500 text-sm">
                    Characters left: {{ 200 - bot.description.length }}
                  </p>
                </div>

                <div v-if="categories" class="w-full my-4 border-b border-t border-gray-200 py-4">
                  <div class="mb-4 w-full">
                  <label for="title" class="block text-gray-700 font-bold mb-2"
                    >Select a category</label
                  >
                  <select
                    v-model="bot.category"
                    type="text"
                    id="title"
                    class="focus:border-blue-600 border-2 w-full rounded-lg px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white"
                    required
                  >
                    <option v-for="(i, index) in categories"  :key="index" :value="i.id">{{ i.title }}</option>
                </select>
                </div>
                </div>
                <div class="mb-4 w-full pt-4 mt-4">
                  <label class="block text-gray-700 font-bold mb-2"
                    ><i class="fa-regular fa-credit-card"></i> Paywall
                    <span
                      class="bg-yellow-500 text-white font-bold rounded-lg px-4 ml-2"
                      >PRO</span
                    ></label
                  >
                </div>
                <div class="w-full">
                  <div
                    v-if="
                      currentUser.subscription.status == 'active' &&
                      currentUser.billing.charges_enabled &&
                      currentUser.billing.payouts_enabled
                    "
                    class="mb-4 w-full"
                  >
                    <label
                      for="price"
                      class="block text-gray-700 font-bold mb-2"
                      >Price</label
                    >
                    <input
                      v-model="bot.price"
                      type="number"
                      id="price"
                      class="focus:border-blue-600 border-2 w-full rounded-lg px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white"
                    />
                  </div>

                  <div
                    class="mb-6 w-full bg-yellow-100 rounded px-5 py-2 text-sm text-yellow-600 w-56 text-center"
                    v-if="!currentUser.billing.charges_enabled || !currentUser.billing.payouts_enabled && currentUser.subscription.status == 'active'"
                  >
                    You need to ensure you have all your billing set up
                    including charges enabled and payouts enabled. You also need
                    to ensure your Pro Plan is active.
                  </div>
                </div>

                <button
                  v-if="!this.avatarLoading"
                  type="submit"
                  class="bg-blue-600 mb-20 hover:bg-blue-800 text-white text-sm rounded-lg cursor-pointer w-40 h-16 flex items-center justify-center"
                >
                  <span class="font-bold mr-2">Save:</span> Add Topics
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import {
  setItem,
  getItem,
  getItems,
  getToken,
  uploadAvatarToFirebase,
} from "@/firebaseUtils";
import ChatbotTile from "@/components/cards/chatbotTile";
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";
export default {
  data() {
    return {
      bot: null,
      avatarLoading: false,
      categories: []
    };
  },
  methods: {
    async initiate() {
      this.bot = await getItem({
        collection: "bots",
        doc: this.$route.params.id,
      });
      this.categories = await getItems({
        collection: "categories"
      });
    },
    async handleSubmit() {
      const item = await setItem({
        collection: "bots",
        doc: this.$route.params.id,
        body: this.bot,
      });

      await this.updateStripePrice();
      this.$router.push({
        name: "EditChatBot",
        params: { id: this.$route.params.id },
      });
    },
    updateStripePrice() {
      return new Promise(async (res, rej) => {
        const accessToken = await getToken();
        const requestData = {
          botId: this.bot.id,
          price: this.bot.price,
          name: this.bot.title,
        };
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };

        const response = await this.axios.post(
          "https://us-central1-chitchat-f3646.cloudfunctions.net/widgets/bot/set-price",
          requestData,
          config
        );

        console.log('response from price set', response)
        res();
      });
    },

    async handleAvatarUpload(event) {
      this.avatarLoading = true;
      const file = event.target.files[0];
      if (!file) return;

      try {
        // Upload the avatar file to Firebase Storage and get the download URL
        const downloadURL = await uploadAvatarToFirebase(file);

        // Save the avatar URL to this.bot.avatar.source
        this.bot.avatar = {
          source: downloadURL,
        };
        this.avatarLoading = false;
      } catch (error) {
        this.avatarLoading = false;
        console.error("Error uploading avatar:", error);
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    this.initiate();
  },
  components: {
    ChatbotTile,
    SideNav,
    PrimaryNav,
  },
};
</script>