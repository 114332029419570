<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div class="flex-grow h-full">
        <div class="max-w-7xl mx-auto p-10 h-full">
          <div class="h-full flex flex-col bg-gray-100 rounded-lg pt-10 px-10">
            <div
              class="flex-grow flex flex-col overflow-y-auto px-4 py-2"
              v-chat-scroll
            >
              <div v-for="message in messages" :key="message.id" class="mb-2">
                <div
                  class="flex items-end justify-start"
                  v-if="message.position === 'bot'"
                >
                  <div class="w-8 h-8 rounded-full bg-gray-200 mr-2"></div>
                  <div class="bg-gray-200 text-gray-500 px-4 py-2 rounded-xl">
                    {{ message.text }}
                    <div class="text-xs text-gray-400 mt-1">
                      {{ getTime(message.created.seconds * 1000) }}
                    </div>
                  </div>
                </div>
                <div class="flex items-end justify-end" v-else>
                  <div class="bg-blue-600 text-white px-4 py-2 rounded-xl">
                    {{ message.text }}
                    <div class="text-xs text-white mt-1">
                      {{ getTime(message.created.seconds * 1000) }}
                    </div>
                  </div>
                  <div class="w-8 h-8 rounded-full bg-blue-600 ml-2"></div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <div class="flex rounded-xl bg-white">
                <div class="flex-grow bg-gray-200 flex items-center px-4 h-14 rounded-l-xl focus:outline-none" v-if="currentSession && currentSession.status =='waitingForResponse'">Chatbot is typing...</div>
                <input v-else
                  v-model="newMessage"
                  type="text"
                  class="flex-grow px-4 h-14 rounded-l-xl focus:outline-none"
                  placeholder="Type your message..."
                />
                <button
                  @click="sendMessage"
                  class="px-4 h-14 rounded-r-xl bg-blue-600 text-white"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getItem, addItem, setItem } from "@/firebaseUtils";
import VueChatScroll from "vue-chat-scroll";
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";

import {
  doc,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export default {
  data() {
    return {
      bot: null,
      messages: [
        
      ],
      newMessage: "",
      currentSession: null,
    };
  },
  methods: {
    async initiate() {
      this.bot = await getItem({
        collection: "bots",
        doc: this.$route.params.id,
      });
      if (!this.$route.query.session) {
        await this.setSession();
      }
      // Either way we want to set a alistner to the session
      await this.getSession();
      // Also we want to listen to the messages of that session
      this.getMessages();
    },
    getMessages() {
      const q = query(
        collection(db, "messages"),
        where("sessionId", "==", this.currentSession.id),
        orderBy("created")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
        this.messages.push({...change.doc.data(), id: change.doc.id})
    }
  });
      });
    },
    setSession() {
      return new Promise(async (res, rej) => {
      const obj = await addItem({
        collection: "sessions",
        body: {
          botId: this.bot.id,
          updated: new Date(),
          created: new Date(),
          lastMessage: null,
          userId: this.currentUser.id,
          status: "published",
        },
      });
      this.currentSession = obj;
      res()
    })
    },
    getSession() {
      return new Promise(async (res, rej) => {
      let _sessionId = this.$route.query.session || this.currentSession.id;
      const unsub = onSnapshot(
        doc(db, "sessions", _sessionId),
        { includeMetadataChanges: true },
        (doc) => {
          this.currentSession = { ...doc.data(), id: doc.id };
        }
      );
      res()
      })
    },
    async sendMessage() {
      if (this.newMessage.trim() !== "") {
        const message = {
          text: this.newMessage.trim(),
          created: new Date(),
          botId: this.bot.id,
          userId: this.currentUser.id,
          sessionId: this.currentSession.id,
          position: "user",
        };

        await addItem({ collection: "messages", body: message });
        await setItem({
          collection: "sessions",
          doc: this.currentSession.id,
          body: {
            updated: new Date(),
            lastMessage: message,
            userId: this.currentUser.id,
            status: "waitingForResponse",
          },
        });

        // this.messages.push(message);
        this.newMessage = "";
      }
    },
    getTime(date) {
      const now = new Date(date);
      return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    this.initiate();
  },
  components: {
    VueChatScroll,
    SideNav,
    PrimaryNav,
  },
};
</script>