<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div class="flex-grow h-full">
        <div v-if="loading" class="max-w-7xl mx-auto p-10">Loading</div>

        <div v-if="!loading" class="max-w-7xl mx-auto p-10">
          <div class="w-full mb-6 mt-10">
            <div class="flex-shrink mb-6 ">
              <h1 class="text-3xl font-bold text-gray-800">Billing</h1>
            </div>

            <div class="flex w-full border border-gray-200 rounded-lg p-6">
              <div class="flex-shrink">
                <span @click="manageSubscription()"
                  class="text-lg text-gray-700 hover:text-blue-600 cursor-pointer font-bold"
                >
                  Subscription Plan
              </span>
                <h3 class="text-xs text-gray-400">
                  Joined {{ $moment(currentUser.created.seconds * 1000).format("DD MMM YYYY") }}
                </h3>
              </div>
              <div
                class="flex-shrink ml-auto mr-10 flex items-center space-x-10 justify-center"
              >

                  
                  <span v-if="currentUser.subscription && currentUser.subscription.status == 'active'"
                    class="bg-yellow-600 rounded px-3 py-2 text-white font-bold text-xs"
                    >You're a PRO</span
                  >
                  <span v-else
                    class="bg-green-100 rounded px-3 py-2 text-green-600 text-xs"
                    >Free Plan</span
                  >
              </div>
              
              <div
                class="flex-shrink flex items-center mr-10 justify-center"
              >

                <div class="flex-shrink">
                  
                  <span v-if="currentUser.subscription && currentUser.subscription.cancel_at"
                    class="bg-red-100 rounded px-3 py-2 text-red-600 font-normal text-xs"
                    >Ends {{ $moment(currentUser.subscription).format("DD MMM YYYY") }}</span
                  >
                </div>
              </div>

              <div @click="manageSubscription()"
                class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-transparent border-blue-600 border text-blue-600 hover:text-white flex items-center justify-center"
              >
                <i class="fa-solid fa-ellipsis-vertical mr-2 text-xs"></i>
                Manage
            </div>
            </div>

            <div class="w-full" v-if="currentUser.subscription.plan.active && currentUser.subscription.plan.amount > 0">
            <h2
              v-if="!currentUser.billing || !currentUser.billing.id"
              @click="setUp()"
              class="text-lg mt-10 text-gray-700 cursor-pointer font-bold"
            >
              Payout Details
            </h2>
            <div
              v-if="!currentUser.billing || !currentUser.billing.id"
              class="w-full h-full bg-yellow-100 text-yellow-600 rounded-lg text-sm py-6 flex items-center justify-center"
            >
              We need to verify your payout details
              <span
                @click="setUp()"
                class="ml-2 cursor-pointer underline text-yellow-600 text-white rounded-lg"
                >Would you like to set it up?</span
              >
            </div>
          

            <div v-else class="w-full flex mt-4">
              <div class="flex w-full border border-gray-200 rounded-lg p-6">
                <div class="flex-shrink">
                  <h2
                    @click="getLink()"
                    class="text-lg text-gray-700 hover:text-blue-600 cursor-pointer font-bold"
                  >
                    Payout Settings
                  </h2>
                  <h3 class="text-xs text-gray-400">
                    Default Currency {{ currentUser.billing.default_currency }}
                  </h3>
                </div>
                <div
                  class="flex-shrink ml-auto mr-10 flex items-center space-x-10 justify-center"
                >
                    <span class="text-blue-600 text-sm"
                      >Country {{ currentUser.billing.country }}</span
                    >

                  <div class="flex-shrink">
                    <span
                      v-if="currentUser.billing.charges_enabled"
                      class="bg-green-100 rounded px-3 py-2 text-green-600 text-xs"
                      >Charges Enabled</span
                    >
                    <span
                      v-else
                      class="bg-red-100 rounded px-3 py-2 text-red-600 text-xs"
                      >Charges Disabled</span
                    >
                  </div>

                  <div class="flex-shrink">
                    <span
                      v-if="currentUser.billing.payouts_enabled"
                      class="bg-green-100 rounded px-3 py-2 text-green-600 text-xs"
                      >Payouts Enabled</span
                    >
                    <span
                      v-else
                      class="bg-red-100 rounded px-3 py-2 text-red-600 text-xs"
                      >Payouts Disabled</span
                    >
                  </div>
                </div>

                <div
                  @click="getLink()"
                  class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-transparent border-blue-600 border text-blue-600 hover:text-white flex items-center justify-center"
                >
                  <i class="fa-solid fa-ellipsis-vertical mr-2 text-xs"></i>
                  Manage
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBots, getItem, getToken } from "@/firebaseUtils";
import ChatbotTile from "@/components/cards/chatbotTile";
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async setUp() {
      this.loading = true;
      // Get access token
      const accessToken = await getToken();
      const requestData = {};
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await this.axios.post(
        "https://us-central1-chitchat-f3646.cloudfunctions.net/widgets/billing/create",
        requestData,
        config
      );

      console.log("response", response);

      await this.getUser();
      this.loading = false;
    },
    async manageSubscription() {
      this.loading = true;
      // Get access token
      const accessToken = await getToken();
      const requestData = {};
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await this.axios.post(
        "https://us-central1-chitchat-f3646.cloudfunctions.net/widgets/billing/manage-link",
        requestData,
        config
      );

      console.log("response", response);

      this.openExternalLinkInNewTab(response.data.url);
      this.loading = false;
    },
    async getLink() {
      this.loading = true;
      const accessToken = await getToken();
      const requestData = {};
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      this.axios
        .post(
          "https://us-central1-chitchat-f3646.cloudfunctions.net/widgets/billing/account-link",
          requestData,
          config
        )
        .then((response) => {
          console.log("response from link gen", response.data.url);
          this.openExternalLinkInNewTab(response.data.url);
          this.loading = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.loading = false;
        });
    },
    openExternalLinkInNewTab(url) {
      const externalLink = url; // Replace with your external link
      window.open(externalLink, "_blank");
    },
    async initiate() {
      this.bots = await getBots(this.currentUser.id);
      await this.getUser();
    },
    getUser() {
      return new Promise(async (res, rej) => {
        try {
          const _user = await getItem({
            collection: "users",
            doc: this.currentUser.id,
          });
          this.$store.commit("updateState", {
            state: "currentUser",
            body: _user,
          });
          console.log("_user", _user);
          res();
        } catch (e) {
          rej(e);
        }
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    if(!this.currentUser.subscription) this.$router.push({name: 'Pricing'})
    this.initiate();
  },
  components: {
    ChatbotTile,
    SideNav,
    PrimaryNav,
  },
};
</script>