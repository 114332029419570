import { initializeApp } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  storageBucket: "chitchat-f3646.appspot.com",
  apiKey: "AIzaSyADSbtrtdl3opZP7gWCHR1zoHq83F36gM0",
  authDomain: "chitchat-f3646.firebaseapp.com",
  projectId: "chitchat-f3646",
  storageBucket: "chitchat-f3646.appspot.com",
  messagingSenderId: "326366107696",
  appId: "1:326366107696:web:94d2fd11c184f60befb5f5",
  measurementId: "G-JMP3X36KVQ"
};
const app = initializeApp(firebaseConfig);

export const getTimeStamp = function(date){
  return Timestamp.fromDate(date);
}

export const db = getFirestore(app);
export const auth = getAuth();
export const messaging = getMessaging(app);
export const storage = getStorage(app);
