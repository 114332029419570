<!-- Solutions.vue -->
<template>
    <div class="bg-white rounded-lg p-6 shadow-md">
      <h2 class="text-2xl text-gray-900 font-bold mb-4">Solutions</h2>
      <ul class="list-disc list-inside text-gray-700">
        <li v-for="solution in solutions" :key="solution.title">
          <div class="flex items-center mb-2">
            <div class="w-12 h-12 rounded-full bg-gray-200 mr-4"></div>
            <div>
              <h3 class="text-lg text-gray-900 font-bold">{{ solution.title }}</h3>
              <p class="text-gray-700">{{ solution.description }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        solutions: [
          {
            title: "For Parents",
            description:
              "Leave a lasting legacy by capturing your knowledge and life experiences, ensuring your loved ones can access your wisdom even after you're gone.",
          },
          {
            title: "For Coaches",
            description:
              "Scale your coaching business and reach a broader audience by transforming your expertise into interactive and engaging chatbots.",
          },
          {
            title: "For Human Resources",
            description:
              "Improve employee engagement and productivity by providing instant access to answers for common HR-related queries and processes.",
          },
        ],
      };
    },
  };
  </script>
  