<template>
  <div class="bg-gray-100 h-screen w-full">
    <div class="container mx-auto py-10">
      <div class="w-full flex justify-center my-10">
      <router-link :to="{name: 'home'}" class="mx-auto flex">
        <h2 class="font-bold text-gray-800">
          <img src="@/assets/wiseText.svg" class="w-32" alt="wise1 logo">
        </h2>
        <img src="@/assets/squarelogo.svg" alt="w1 logo" class="w-8">
      </router-link>
    </div>
      <div class="max-w-md mx-auto bg-white rounded-lg p-6 shadow-md">
        <h2 class="text-xl text-gray-900 font-bold mb-6">Forgotten your password?</h2>
        <form @submit.prevent="handleSubmit">
          <div class="mb-4">
            <label for="email" class="block text-gray-700 text-sm mb-2">Enter your email - we will email you intructions.</label>
            <input v-model="email" placeholder="Email address" type="email" id="email" class="w-full rounded-lg h-16 focus:border-gray-100 border-2 border-transparent px-4 py-2 bg-gray-200 focus:outline-none focus:bg-white" required>
          </div>
        
          <button type="submit" class="bg-purple-800 hover:bg-blue-800 text-white font-bold py-2 h-16 flex items-center justify-center px-4 rounded cursor-pointer w-full">Reset Password</button>
        </form>

        <div class="my-6">
            <p class="text-gray-700 text-center mt-4">
        
        <span
          class="text-blue-600 text-sm cursor-pointer"
          @click="$router.push({ name: 'Login' })"
          >Go Back</span
        >
      </p>
        </div>

        <p class="text-gray-700 text-sm text-center mt-4">
        Don't have an account yet?
        <span
          class="text-blue-600 cursor-pointer"
          @click="$router.push({ name: 'SignUp' })"
          >Create a free chatbot</span
        >
      </p>

      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { resetPassword } from "@/firebaseUtils";

  export default {
    data() {
      return {
        email: '',
        password: '',
      };
    },
    methods: {
      async handleSubmit() {
        await resetPassword(this.email, this.password)
        this.$router.push({ name: "Login" });
      },
    },
  };
  </script>
  