<template>
  <div
    class="mb-2 w-full flex rounded-lg border border-gray-200 rounded p-6 mb-2"
  >
    <div class="flex-shrink">
      <h2
        class="text-lg text-gray-700 hover:text-blue-600 cursor-pointer font-bold"
      >
        {{ bot.title }}
      </h2>
      <h3 class="text-xs text-gray-400">Created: 1 July 2023</h3>
    </div>
    <div class="flex-grow flex items-center space-x-10 justify-center">
      <div class="flex-shrink">
        <span class="text-blue-600 text-sm">{{ items.length }} topics</span>
      </div>
      
      <div class="flex-shrink">
        <span class="bg-gray-100 rounded px-3 py-2 text-gray-600 text-xs"
          >Free Teir</span
        >
      </div>
    </div>
    <div v-if="edit" class="flex-shrink">
      <router-link :to="{name: 'CreateChatBot', params:{id: this.bot.id}}"
        class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-transparent border-blue-600 border text-blue-600 hover:text-white flex items-center justify-center"
      >
        <i class="fa-solid fa-ellipsis-vertical mr-2 text-xs"></i> Edit
      </router-link>
    </div>
    <div v-if="!edit" class="flex-shrink">
      <router-link :to="{name: 'EditChatBot', params:{id: this.bot.id}}"
        class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-transparent border-blue-600 border text-blue-600 hover:text-white flex items-center justify-center"
      >
        <i class="fa-solid fa-ellipsis-vertical mr-2 text-xs"></i> Manage
    </router-link>
    </div>
    <router-link :to="{name: 'ChatWindow', params:{id: this.bot.id}}" v-if="!edit" class="flex-shrink ml-4">
      <div
        class="w-32 h-12 hover:bg-green-700 cursor-pointer rounded-lg bg-green-500 text-white flex items-center justify-center"
      >
        Try Chat
      </div>
    </router-link>
  </div>
</template>

<script>
import { getTopics, getItem } from "@/firebaseUtils";

export default {
    props:['bot', 'edit'],
    data(){
      return{
        items:[],
        bot: null
      }
    },
    computed:{
      currentUser(){
        return this.$store.state.currentUser;
      }
    },
    methods:{
      async initiate(){
        this.items = await getTopics(this.currentUser.id, this.bot.id)
        this.bot = await getItem({collection: "bots", doc: this.bot.id})
      }
    },
    created(){
      this.initiate()
    }
}
</script>