// firebaseUtils.js
import { storage, db, auth } from "./firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { setDoc, addDoc, doc, getDoc, collection, query, getDocs, where } from "firebase/firestore";
import { createUserWithEmailAndPassword, currentUser, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
// Initialize Firestore
export async function uploadAvatarToFirebase(file) {
    return new Promise(async (res, rej) => {
        try {
             // Create a unique file name for the avatar (you can use a different naming convention if needed)
             const fileName = `${Date.now()}_${file.name}`;
            // Create a reference to the Firebase Storage bucket
            const storageRef = ref(storage, `sourceAvatars/${fileName}`);
            // Upload the file to Firebase Storage
            uploadBytes(storageRef, file).then((snapshot) => {
                console.log("snapshot", snapshot)
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    // Get the download URL of the uploaded file
                    console.log("downloadURL", downloadURL)
                    res(downloadURL)
                  });
              });

            
        } catch (error) {
            console.log("error", error)
            rej(error)
        }
    })
};

// Get all documents from a collection
export async function getItem(payload) {
    return new Promise(async (res, rej) => {
        try {
            const docRef = doc(db, payload.collection, payload.doc);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                res({ ...docSnap.data(), id: docSnap.id })
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
            }
        } catch (e) {
            rej(e)
        }
    })
}

// Get all bots
export async function getItems(payload) {
    return new Promise(async (res, rej) => {
        try {
            const q = query(collection(db, payload.collection));

            const querySnapshot = await getDocs(q);
            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({ ...doc.data(), id: doc.id })
            });
            res(arr)
        } catch (e) {
            rej(e)
        }
    })
}
// Get all bots
export async function getBots(userId) {
    return new Promise(async (res, rej) => {
        try {
            const q = query(collection(db, "bots"), where("userId", "==", userId));

            const querySnapshot = await getDocs(q);
            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({ ...doc.data(), id: doc.id })
            });
            res(arr)
        } catch (e) {
            rej(e)
        }
    })
}
// Get items
export async function getTopics(userId, botId) {
    return new Promise(async (res, rej) => {
        try {
            const q = query(collection(db, "topics"), where("userId", "==", userId), where("botId", "==", botId));

            const querySnapshot = await getDocs(q);
            let arr = []
            querySnapshot.forEach((doc) => {
                arr.push({ ...doc.data(), id: doc.id })
            });
            res(arr)
        } catch (e) {
            rej(e)
        }
    })
}


export async function addItem(payload) {
    return new Promise(async (res, rej) => {
        try {
            const docRef = await addDoc(collection(db, payload.collection), payload.body);
            res({ ...payload.body, id: docRef.id })
        } catch (e) {
            rej(e)
        }
    })
}

export async function setItem(payload) {
    return new Promise(async (res, rej) => {
        try {
            await setDoc(doc(db, payload.collection, payload.doc), payload.body)
            res()
        } catch (e) {
            rej(e)
        }
    })
}

// Create User Auth
export async function getToken() {
    return new Promise(async (res, rej) => {
        const user = auth.currentUser
        if (user) {
            try {
              const accessToken = await user.getIdToken();
              console.log('Access Token:', accessToken);
              res(accessToken)
            } catch (error) {
              console.error('Error getting access token:', error);
              rej(error)
            }
          } else {
            console.log('No user signed in.');
          }
    })
}

export async function resetPassword(email, password) {
    return new Promise((res, rej) => {
        sendPasswordResetEmail(auth, email)
            .then((result) => {
                res()
            })
            .catch((error) => {
                // Handle any error messages from Firebase
                console.error("Error creating user:", error);
                rej(error)
            });
    })
}

export async function createUserAuth(email, password) {
    return new Promise((res, rej) => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((result) => {
                res(result.user.uid)
            })
            .catch((error) => {
                // Handle any error messages from Firebase
                console.error("Error creating user:", error);
                rej(error)
            });
    })
}

export async function signIn(email, password) {
    return new Promise((res, rej) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                res(result.user.uid)
            })
            .catch((error) => {
                // Handle any error messages from Firebase
                console.error("Error creating user:", error);
                rej(error)
            });
    })
}

