<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div class="flex-grow h-full">
        <div v-if="loading" class="max-w-7xl mx-auto p-10">Loading</div>

        <div v-if="!loading" class="max-w-7xl mx-auto p-10">
          <div class="w-full mb-6 mt-10">
            <div class="flex-shrink">
              <h1 class="text-3xl font-bold text-gray-800">Pricing</h1>
            </div>

            <stripe-pricing-table
              pricing-table-id="prctbl_1NchqdLjHAMNXcVJm9LklLl2"
              publishable-key="pk_test_51NccnXLjHAMNXcVJ40wBddbKWu5ttkRgzI9U4wpYVYLX8JRqTLgCiDknNFstXFMfgtWpGWoWzdGZKda0Ioz0LEVw00l2QuvR81"
              :client-reference-id="currentUser.id"
            >
            </stripe-pricing-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";

export default {
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.addScriptTags();
  },
  methods: {
    addScriptTags() {
      const script1 = document.createElement("script");
      script1.src = "https://js.stripe.com/v3/pricing-table.js";
      script1.async = true;

      document.head.appendChild(script1);
    },
  },
  components: {
    PrimaryNav,
    SideNav,
  },
};
</script>
  