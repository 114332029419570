<template>
  <div class="w-full flex flex-col h-screen">
    <primary-nav />
    <div class="w-full flex h-full text-gray-700">
      <side-nav />

      <div v-if="bot" class="flex-grow h-full pt-10">

      
        <div class="max-w-7xl mx-auto p-10">
          <div class="w-full mb-6 flex items-center">
            <div class="flex-shrink">
                <router-link :to="{name: 'ChatBots'}" class="text-blue-600 cursor-pointer hover:underline text-sm"
              >Chatbots</router-link
            >
              <h1 class="text-3xl font-bold text-gray-800">Manage Chatbot</h1>
              <span
                class="bg-gray-100 rounded-lg px-3 py-1 text-gray-500 text-xs"
                >{{ bot.id }}</span
              >
            </div>
          </div>

          <div class="w-full">
            <chatbot-tile :bot="bot" :edit="true" />
          </div>

          <div class="w-full my-6 flex">
            <div class="flex-shrink">
              <h2 class="text-xl font-bold text-gray-800">Add Topic</h2>
            </div>
            <div class="flex-shrink ml-auto">
              <div @click="createTopic()"
                class="w-40 h-12 hover:bg-blue-700 cursor-pointer rounded-lg bg-blue-600 text-white flex items-center justify-center"
              >
                <i class="fa-solid fa-plus mr-2 text-xs"></i> Add Topic
            </div>
            </div>
          </div>

          <div
            v-if="items.length > 0"
            class="w-full h-full flex flex-wrap"
          >
            <div class="w-full " v-for="(i, index) in items" :key="index">
              <topic-tile :topic="i" class="w-full"/>
            </div>
          </div>
          <div
            class="w-full h-full mt-20 flex items-center justify-center"
            v-else
          >
            This chatbot has no wisdom yet
            <span
              @click="createTopic()"
              class="ml-2 cursor-pointer hover:text-blue-700 text-blue-600 text-white rounded-lg"
              >Add a topic to get started?</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getItem, addItem, getTopics } from "@/firebaseUtils";
import ChatbotTile from "@/components/cards/chatbotTile";
import PrimaryNav from "@/components/navs/primaryNav.vue";
import SideNav from "@/components/navs/sideNav.vue";
import TopicTile from "@/components/cards/topicsTile.vue";

export default {
  data() {
    return {
      bot: null,
      items: []
    };
  },
  methods: {
    async initiate() {
      this.bot = await getItem({
        collection: "bots",
        doc: this.$route.params.id,
      });
      this.items = await getTopics(this.currentUser.id, this.bot.id)
    },
    async createTopic(){
      const topic = await addItem({
        collection: "topics",
        body: {
          title: "",
          body: "",
          botId: this.$route.params.id,
          userId: this.currentUser.id,
          type: "text", // Later I want to ingest docs and other knowledge bases calenders etc.
          created: new Date(),
          updated: new Date()
        }
      })
      if(topic.id) this.$router.push({name: 'EditTopic', params:{id: this.$route.params.id, topicId: topic.id}})
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  created() {
    this.initiate();
  },
  components: {
    ChatbotTile,
    SideNav,
    PrimaryNav,
    TopicTile
  },
};
</script>